aa<template>
  <div>
    <b-row v-if="mailchimpSettings">
      <b-col
        lg="6"
        md="8"
        sm="12"
      >
        <b-card>
          <b-card-header class="p-0">
            <h4 class="mb-3">
              MailChimp Integration
            </h4>
            <div class="mx-1 d-none d-md-block" />
            <b-card-text
              v-if="mailchimpSettings.api_key"
              class="mb-3 d-inline-flex align-items-center"
            >
              <label
                for="activate_integration"
                class="ml-auto mb-0 cursor-pointer"
              >Activate Integration</label>
              <b-form-checkbox
                id="activate_integration"
                v-model="mailchimpSettings.is_active"
                switch
                class="ml-1"
                :disabled="isSubmitting"
                @change="updateMailchimpIntegrationSettings"
              />
            </b-card-text>
          </b-card-header>
          <div class="d-flex flex-column align-items-start">
            <template v-if="mailchimpSettings.api_key">
              <div>You are connected to Mailchimp with:</div>
              <div
                v-if="mailchimpSettings"
                class="current-api-key mb-2"
              >
                <span>Current API key:</span>
                {{ mailchimpSettings.api_key }}
              </div>
            </template>

            <b-button
              variant="primary"
              :to="{ name: 'setup-integration', params: { integrationName: 'mailchimp' }}"
            >{{ mailchimpSettings.api_key ? 'Change API Key' : 'Add API Key' }}
            </b-button>
          </div>
          <div
            v-if="mailchimpSettings.api_key"
            class="mt-2"
          >
            <b-spinner
              v-if="showListLoader"
              label="Loading..."
            />
            <template v-if="!showListLoader">
              <div class="h4 mb-1">
                Choose a list from your Mailchimp account
              </div>
              <div class="mb-1">New Plan Your Visit contacts will be added to the "Interest Sign ups" list in
                your Mailchimp account.
              </div>
              <b-form-select
                v-if="options.length"
                v-model="mailchimpSettings.add_to_audience_id"
                :options="options"
                class="btn dropdown-toggle btn-primary w-auto"
                @change="updateMailchimpIntegrationSettings"
              />
            </template>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCardText, BCol, BFormCheckbox, BFormSelect, BRow, BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import { cloneDeep } from 'lodash'
import AccountService from '@/services/AccountService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCard,
    BCardHeader,
    BCardText,
    BFormSelect,
    BSpinner,
    BFormCheckbox,
  },
  data() {
    return {
      isSubmitting: false,
      mailchimpSettings: null,
      mailChimpList: null,
      showListLoader: true,
      options: [],
    }
  },
  computed: {},
  created() {
    this.mailchimpSettings = cloneDeep(store.getters['auth/account'].mail_chimp_integration)
    this.mailchimpSettings.is_active = !!this.mailchimpSettings.is_active
    if (this.mailchimpSettings.api_key) {
      this.refreshMailchimpLists()
    }
  },
  methods: {
    refreshMailchimpLists() {
      const self = this
      AccountService.getMailchimpList(this.mailchimpSettings.api_key)
        .then(apiResponse => {
          self.options = []
          if (apiResponse.data.data.length) {
            for (const i in apiResponse.data.data) {
              self.options.push({
                value: apiResponse.data.data[i].id,
                text: apiResponse.data.data[i].name,
              })
            }
          }
          self.mailchimpList = apiResponse.data.data
          self.showListLoader = false
        })
    },
    updateMailchimpIntegrationSettings() {
      const self = this
      try {
        this.isSubmitting = true
        AccountService.updateMailchimpIntegrationSettings(
          {
            is_active: self.mailchimpSettings.is_active,
            add_to_audience_id: self.mailchimpSettings.add_to_audience_id,
          },
        ).then(response => {
          store.dispatch('auth/setAuthUser', response.data.data)

          self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'BellIcon',
              text: 'Item updated successfully',
              variant: 'success',
            },
          })
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.isSubmitting = false
      }
    },
  },
}
</script>
<style scoped>
.current-api-key {
  width: 100%;
  padding: 30px 20px 20px 20px;
  background: #F0F0F0;
  position: relative;
}

.current-api-key > span {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.7rem;
  background: #7366f0;
  color: #FFF;
  padding: 2px 10px;
}
</style>
